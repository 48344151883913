<div class="relative mb-14 overflow-hidden">
  <div class="container">
    <h2
      class="heading-underline-centered mx-auto mb-8 w-fit text-center font-oswald text-3xl font-bold md:text-4xl"
    >
      {{ 'home.ourTeam.title' | translate }}
    </h2>
    <div class="grid grid-cols-1 items-center gap-4 lg:grid-cols-2 xl:gap-0">
      <div class="w-full">
        <swiper-container class="custom-swiper" init="false" #swiper>
          @for (slide of slides; track $index) {
            <swiper-slide>
              <div class="flex items-center justify-center">
                <img
                  [src]="slide.img"
                  [alt]="$index"
                  class="aspect-[3/4] min-h-[512px] w-auto object-cover shadow-md"
                  loading="lazy"
                />
              </div>
            </swiper-slide>
          }
        </swiper-container>
      </div>
      <div class="border border-accent bg-white/40 px-4 py-6 lg:py-8">
        <div class="flex flex-col items-start" #slideContent>
          <span
            class="font-poppins text-xs font-medium uppercase text-primary"
            >{{ slides[activeSlideIndex].function }}</span
          >
          <strong class="mb-4 font-oswald text-lg">{{
            slides[activeSlideIndex].name
          }}</strong>
          <div class="custom-scroll h-[320px] overflow-auto">
            <p
              class="text-sm"
              [innerHTML]="slides[activeSlideIndex].desc | nl2br"
            ></p>
          </div>
          <div class="pt-4 text-sm font-semibold italic">
            <a
              [href]="'tel:' + slides[activeSlideIndex].phone"
              class="mb-3 flex items-center gap-2 transition-colors hover:text-primary"
              appRipple
            >
              <span class="text-primary">
                <i-lucide [img]="icons['phone']" />
              </span>
              <span>{{ slides[activeSlideIndex].phone }}</span>
            </a>
            <a
              [href]="'mailto:' + slides[activeSlideIndex].mail"
              class="flex items-center gap-2 transition-colors hover:text-primary"
              appRipple
            >
              <span class="text-primary">
                <i-lucide [img]="icons['mail']" />
              </span>
              <span>{{ slides[activeSlideIndex].mail }}</span>
            </a>
          </div>
        </div>
        <div class="flex items-center justify-center gap-6 pt-4 lg:pt-7">
          <button
            class="swiper-prev btn btn-circle btn-outline btn-accent"
            appRipple
          >
            <i-lucide [img]="icons['moveLeft']"></i-lucide>
          </button>
          <button
            class="swiper-next btn btn-circle btn-outline btn-accent"
            appRipple
          >
            <i-lucide [img]="icons['moveRight']"></i-lucide>
          </button>
        </div>
      </div>
    </div>
    <div class="thumbs mt-5">
      <div class="w-full">
        <swiper-container
          class="custom-thumb-swiper"
          init="false"
          #swiperThumbs
        >
          @for (slide of slides; track $index) {
            <swiper-slide
              [class]="
                [
                  'cursor-pointer',
                  {
                    'font-bold drop-shadow-lg': activeSlideIndex === $index
                  }
                ] | cn
              "
            >
              <div
                class="flex flex-col items-center justify-center text-center"
              >
                <img
                  [src]="slide.img"
                  [alt]="$index"
                  [class]="
                    [
                      'aspect-[3/4] w-full object-cover shadow-md',
                      {
                        'border-2 border-solid border-accent':
                          activeSlideIndex === $index
                      }
                    ] | cn
                  "
                  loading="lazy"
                />
                <span class="my-2 font-oswald text-base uppercase text-black">{{
                  slide.name
                }}</span>
              </div>
            </swiper-slide>
          }
        </swiper-container>
      </div>
    </div>
  </div>
</div>
