import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

import { environment } from '@environments/environment'

const MOCK_API_RESPONSE_TYPE: {
  [key: string]: { title: string; description: string }
} = {
  home: {
    title: 'Home',
    description: 'Home page description.'
  },
  aboutUs: {
    title: 'About',
    description: 'About page description.'
  },
  contact: {
    title: 'Contact',
    description: 'Contact page description.'
  },
  timetable: {
    title: 'Timetable',
    description: 'Timetable page description.'
  },
  privacyPolicy: {
    title: 'Privacy policy',
    description: 'Privacy policy page description.'
  },
  termsAndConditions: {
    title: 'Terms and conditions',
    description: 'Terms and conditions page description.'
  }
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private titleService: Title
  ) {}

  setMetaTags(page?: string): void {
    this._addRobots()

    if (page && MOCK_API_RESPONSE_TYPE[page]) {
      const data = MOCK_API_RESPONSE_TYPE[page]

      this._setTitle(data.title)
      this.meta.updateTag({ name: 'description', content: data.description })
    } else {
      this.titleService.setTitle(environment.siteName)
      this.meta.updateTag({ name: 'description', content: '' })
    }
  }

  public setRawMetaTags(title: string, description = '') {
    this._setTitle(title)
    this.meta.updateTag({ name: 'description', content: description })
  }

  private _setTitle(title: string) {
    this.titleService.setTitle(`${title} | ${environment.siteName}`)
  }

  private _addRobots() {
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' })
    }
  }
}
