import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Injectable, inject, signal } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

enum MediaQueries {
  XS = '(max-width: 575.9px)',
  SM = '(min-width: 576px)',
  MD = '(min-width: 768px)',
  LG = '(min-width: 992px)',
  XL = '(min-width: 1200px)'
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  private breakpointObserver = inject(BreakpointObserver)

  private _isLoadedSubject = signal(false)
  private _isXsSubject = signal(false)
  private _isSmSubject = signal(false)
  private _isMdSubject = signal(false)
  private _isLgSubject = signal(false)
  private _isXlSubject = signal(false)

  isLoaded = this._isLoadedSubject.asReadonly()
  isXs = this._isXsSubject.asReadonly()
  isSm = this._isSmSubject.asReadonly()
  isMd = this._isMdSubject.asReadonly()
  isLg = this._isLgSubject.asReadonly()
  isXl = this._isXlSubject.asReadonly()

  constructor() {
    this.breakpointObserver
      .observe([
        MediaQueries.XS,
        MediaQueries.SM,
        MediaQueries.MD,
        MediaQueries.LG,
        MediaQueries.XL
      ])
      .pipe(takeUntilDestroyed())
      .subscribe(({ breakpoints }: BreakpointState) => {
        this._isXsSubject.set(breakpoints[MediaQueries.XS])
        this._isSmSubject.set(breakpoints[MediaQueries.SM])
        this._isMdSubject.set(breakpoints[MediaQueries.MD])
        this._isLgSubject.set(breakpoints[MediaQueries.LG])
        this._isXlSubject.set(breakpoints[MediaQueries.XL])

        this._isLoadedSubject.set(Object.values(breakpoints).includes(true))
      })
  }
}
