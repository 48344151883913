import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  input,
  InputSignal,
  Renderer2
} from '@angular/core'

@Directive({
  selector: '[appRipple]',
  standalone: true
})
export class RippleDirective implements AfterViewInit {
  private _animationDuration = 600
  private _rippleSize = 100

  appRipple: InputSignal<string> = input('')

  constructor(
    private _elRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const { offsetX, offsetY } = event

    const ripple = this._renderer.createElement('span')
    this._renderer.addClass(ripple, 'ripple')
    this._renderer.setStyle(
      ripple,
      'top',
      `${offsetY - this._rippleSize / 2}px`
    )
    this._renderer.setStyle(
      ripple,
      'animation-duration',
      `${this._animationDuration}ms`
    )
    this._renderer.setStyle(
      ripple,
      'left',
      `${offsetX - this._rippleSize / 2}px`
    )
    this._renderer.setStyle(ripple, 'width', `${this._rippleSize}px`)
    this._renderer.setStyle(ripple, 'height', `${this._rippleSize}px`)
    if (this.appRipple()) {
      this._renderer.setStyle(ripple, 'background-color', this.appRipple())
    }

    this._renderer.appendChild(this._elRef.nativeElement, ripple)

    setTimeout(() => {
      this._renderer.removeChild(this._elRef.nativeElement, ripple)
    }, this._animationDuration)
  }

  ngAfterViewInit() {
    const nativeEl = this._elRef.nativeElement
    const children = nativeEl.children

    for (let i = 0; i < children.length; i++) {
      this._renderer.setStyle(children[i], 'pointer-events', 'none')
      this._renderer.setStyle(children[i], 'position', 'relative')
      this._renderer.setStyle(children[i], 'z-index', '1')
    }

    if (!nativeEl.classList.contains('absolute'))
      this._renderer.setStyle(nativeEl, 'position', 'relative')
    this._renderer.setStyle(nativeEl, 'overflow', 'hidden')
  }
}
