import { NgOptimizedImage } from '@angular/common'
import { Component, inject, ChangeDetectionStrategy } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { LucideAngularModule, Mail, MapPinned, Phone } from 'lucide-angular'

import {
  addressData,
  contactData,
  socialsData
} from '@core/constants/mockData.constants'
import { IconRegistry } from '@core/models/ui'
import { LangService } from '@core/services/lang/lang.service'
import { environment } from '@environments/environment'
import { SocialIconComponent } from '@shared/components/social-icon/social-icon.component'

type FooterItemType = {
  label: string
  href: string
  target?: '_blank' | '_self'
  ref?: string
}

type FooterMenuType = {
  name: string
  elements: FooterItemType[]
}

type FooterData = {
  [key: string]: FooterMenuType[]
}

type AppsData = {
  name: string
  url: string
  buttonImageSrc: string
  width: number
  height: number
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule,
    LucideAngularModule,
    SocialIconComponent
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  protected readonly lang = inject(LangService)

  protected readonly currentYear = new Date().getFullYear()
  protected readonly siteName = environment.siteName
  protected readonly icons: IconRegistry = {
    phone: Phone,
    mail: Mail,
    map: MapPinned
  }
  protected readonly contact = contactData
  protected readonly address = addressData
  protected readonly socials = socialsData

  protected readonly titleClass = 'mt-0 mb-3 text-lg font-medium text-secondary'

  protected readonly apps: AppsData[] = [
    {
      name: `${this.siteName} - Android App`,
      url: '#',
      buttonImageSrc: 'assets/img/google-play-white.webp',
      width: 116,
      height: 35
    },
    {
      name: `${this.siteName} - iOS App`,
      url: '#',
      buttonImageSrc: 'assets/img/app-store-white.webp',
      width: 116,
      height: 35
    }
  ]

  protected readonly menus: FooterData = {
    pl: [
      {
        name: 'Regulaminy',
        elements: [
          {
            label: 'Regulamin KSW Szczecinek',
            href: '/assets/docs/regulamin-ksw-szczecinek.pdf',
            target: '_blank'
          },
          {
            label: 'Regulamin KSW Biały Bór',
            href: '/assets/docs/regulamin-ksw-szczecinek-bialy-bor.pdf',
            target: '_blank'
          },
          {
            label: 'Regulamin finansowy',
            href: '/assets/docs/regulamin-finasnowy-ksw-szczecinek.pdf',
            target: '_blank'
          }
        ]
      },
      {
        name: 'Inne pliki',
        elements: [
          {
            label: 'Deklaracja KSW Szczecinek',
            href: '/assets/docs/deklaracja-ksw.pdf',
            target: '_blank'
          },
          {
            label: 'Deklaracja KSW Biały Bór',
            href: '/assets/docs/deklaracja-ksw-bialy-bor.pdf',
            target: '_blank'
          },
          {
            label: 'Karta zdrowia',
            href: '/assets/docs/karta-zdrowia.pdf',
            target: '_blank'
          }
        ]
      },
      {
        name: 'Grafik zajęć',
        elements: [
          {
            label: 'Kickboxing/K1',
            href: '/'
          },
          {
            label: 'Taekwondo olimpijskie',
            href: '/'
          },
          {
            label: 'Kickboxing dla kobiet',
            href: '/'
          },
          {
            label: 'Boks',
            href: '/'
          }
        ]
      }
    ],
    en: [
      {
        name: 'Regulations',
        elements: [
          {
            label: 'Regulations of KSW Szczecinek',
            href: '/assets/docs/regulamin-ksw-szczecinek.pdf',
            target: '_blank'
          },
          {
            label: 'Regulations of KSW Biały Bór',
            href: '/assets/docs/regulamin-ksw-szczecinek-bialy-bor.pdf',
            target: '_blank'
          },
          {
            label: 'Financial Regulations',
            href: '/assets/docs/regulamin-finasnowy-ksw-szczecinek.pdf',
            target: '_blank'
          }
        ]
      },
      {
        name: 'Other files',
        elements: [
          {
            label: 'Declaration of KSW Szczecinek',
            href: '/assets/docs/deklaracja-ksw.pdf',
            target: '_blank'
          },
          {
            label: 'Declaration of KSW Biały Bór',
            href: '/assets/docs/deklaracja-ksw-bialy-bor.pdf',
            target: '_blank'
          },
          {
            label: 'Health card',
            href: '/assets/docs/karta-zdrowia.pdf',
            target: '_blank'
          }
        ]
      },
      {
        name: 'Schedule',
        elements: [
          {
            label: 'Kickboxing/K1',
            href: '/'
          },
          {
            label: 'Olympic taekwondo',
            href: '/'
          },
          {
            label: 'Kickboxing for women',
            href: '/'
          },
          {
            label: 'Boks',
            href: '/'
          }
        ]
      }
    ]
  }
}
