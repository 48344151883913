import { UpperCasePipe } from '@angular/common'
import {
  Component,
  ElementRef,
  InputSignal,
  Signal,
  input,
  viewChild,
  ChangeDetectionStrategy
} from '@angular/core'
import { RouterLink } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

import { BoxButtonDirective } from '@shared/directives/box-button/box-button.directive'
import { RippleDirective } from '@shared/directives/ripple/ripple.directive'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-discipline',
  standalone: true,
  imports: [
    TranslateModule,
    UpperCasePipe,
    BoxButtonDirective,
    RippleDirective,
    RouterLink
  ],
  templateUrl: './discipline.component.html'
})
export class DisciplineComponent {
  private _videoRef: Signal<ElementRef<HTMLVideoElement>> =
    viewChild.required('video')
  src: InputSignal<string> = input.required()
  name: InputSignal<string> = input.required()
  text: InputSignal<string> = input.required()
  link: InputSignal<string> = input.required()

  private get _videoElement() {
    return this._videoRef().nativeElement
  }

  get videoSrcValue() {
    return this.src()
  }

  get nameValue() {
    return this.name()
  }

  get textValue() {
    return this.text()
  }

  get linkValue() {
    return this.link()
  }

  toggleVideo() {
    if (this._videoElement.paused) {
      this._videoElement.play()
    } else {
      this._videoElement.pause()
    }
  }
}
