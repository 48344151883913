<div
  class="group relative aspect-discipline"
  (mouseenter)="toggleVideo()"
  (mouseleave)="toggleVideo()"
>
  <video
    class="absolute size-full object-cover"
    muted
    loop
    [src]="videoSrcValue"
    #video
  ></video>
  <div
    class="absolute flex size-full flex-col items-center justify-center bg-black/30 text-center transition-colors group-hover:bg-black/70"
  >
    <h4 class="font-oswald text-3xl text-white md:text-4xl">
      {{ nameValue | uppercase }}
    </h4>
    <p class="mt-5 hidden max-w-80 text-white group-hover:block">
      {{ textValue }}
    </p>
    <a
      class="btn-primary mt-8 hidden group-hover:flex"
      [routerLink]="linkValue"
      appRipple
      appBox
    >
      <span>{{ 'global.readMore' | translate }}</span>
    </a>
  </div>
</div>
