import { NgOptimizedImage } from '@angular/common'
import {
  Component,
  InputSignal,
  input,
  ChangeDetectionStrategy
} from '@angular/core'

import { environment } from '@environments/environment'
import { CnPipe } from '@shared/pipes/cn/cn.pipe'
import { cn } from '@shared/utils/cn'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sticky-bg',
  standalone: true,
  imports: [NgOptimizedImage, CnPipe],
  templateUrl: './sticky-bg.component.html'
})
export class StickyBgComponent {
  bgSrcInput: InputSignal<string> = input('assets/logo-dark-caption.svg')
  width: InputSignal<number> = input(585)
  height: InputSignal<number> = input(623)
  additionalClass: InputSignal<string> = input('')
  additionalImageClass: InputSignal<string> = input('')
  isPriority: InputSignal<boolean> = input(false)

  protected siteName = environment.siteName

  get bgSrc() {
    return this.bgSrcInput()
  }

  protected readonly cn = cn
}
