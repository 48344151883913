import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http'
import { inject } from '@angular/core'
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  throwError
} from 'rxjs'

import { AuthService } from '@core/services/auth/auth.service'

const refreshTokenSubject = new BehaviorSubject<string | null>(null)
let isRefreshing = false

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService)
  const accessToken = authService.accessToken()

  const authReq = accessToken ? addTokenToRequest(req, accessToken) : req

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && !req.url.includes('/auth/')) {
        return handleAuthError(req, next, authService)
      }
      return throwError(() => error)
    })
  )
}

const handleAuthError = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService
): Observable<HttpEvent<unknown>> => {
  if (!isRefreshing) {
    isRefreshing = true
    refreshTokenSubject.next(null)

    return authService.refreshToken().pipe(
      switchMap(({ accessToken }: { accessToken: string }) => {
        isRefreshing = false
        refreshTokenSubject.next(accessToken)

        return next(addTokenToRequest(req, accessToken))
      }),
      catchError((err) => {
        isRefreshing = false
        authService.logout()
        return throwError(() => err)
      })
    )
  }

  return refreshTokenSubject.pipe(
    filter((token) => token != null),
    take(1),
    switchMap((token) => next(addTokenToRequest(req, token)))
  )
}

const addTokenToRequest = (request: HttpRequest<unknown>, token: string) =>
  request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    }
  })
