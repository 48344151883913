import { Component, ChangeDetectionStrategy } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

import { ToastComponent } from '@features/toast/toast.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent],
  template: '<ng-container><router-outlet /><app-toast /></ng-container>',
  providers: [CookieService]
})
export class AppComponent {}
