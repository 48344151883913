<section>
  <div class="container">
    <div class="my-36">
      <div
        class="grid"
        [class]="{
          'grid-cols-[80px_repeat(6,1fr)]': daysLength() === 6,
          'grid-cols-[80px_repeat(5,1fr)]': daysLength() === 5,
          'grid-cols-[80px_repeat(4,1fr)]': daysLength() === 4,
          'grid-cols-[80px_repeat(3,1fr)]': daysLength() === 3,
          'grid-cols-[80px_repeat(2,1fr)]': daysLength() === 2,
          'grid-cols-[80px_repeat(1,1fr)]': daysLength() === 1,
        }"
      >
        <div class="p-2.5 border-r border-r-gray-300 mt-5"></div>
        @for (day of daysArray; track $index) {
          <div class="text-center font-bold bg-gray-100 p-2.5 border border-b-0 border-gray-300 border-l-transparent mt-5" >{{ day }}</div>
        }
      </div>
      <div
        class="grid border-b border-b-gray-300"
        [class]="{
          'grid-cols-[80px_repeat(6,1fr)]': daysLength() === 6,
          'grid-cols-[80px_repeat(5,1fr)]': daysLength() === 5,
          'grid-cols-[80px_repeat(4,1fr)]': daysLength() === 4,
          'grid-cols-[80px_repeat(3,1fr)]': daysLength() === 3,
          'grid-cols-[80px_repeat(2,1fr)]': daysLength() === 2,
          'grid-cols-[80px_repeat(1,1fr)]': daysLength() === 1,
        }"
      >
        @for (time of timeSlots; track $index) {
          <div
            class="col-start-1 grid items-center justify-center font-bold border-t-transparent bg-gray-200 border border-b-0 border-gray-300"
            [class]="$index % 6 === 0 ? 'border-t-gray-300' : 'overflow-hidden p-0 bg-transparent h-0'"
            [style.gridRow]="(timeSlots.indexOf(time) + 2) + '/ span 6' "
          >
            {{ time }}
          </div>
        }
  
        @for (daySlots of activitisSlotsObject | keyvalue; let dayIndex = $index; track dayIndex) {
          @for (slot of daySlots.value; let i = $index; track i) {
            @if (slot?.name && slot.name !== 'blocked') {
              <div
                [attr.data-time]="timeSlots[i]"
                [style.gridRow]="slot?.rowspan ? (i + 2) + ' / span ' + slot.rowspan : i+2"
                [style.gridColumn]="dayIndex + 2"
                class="flex text-center relative border-r border-r-gray-300"
                [class]="$index % 6 !== 0 ? '' : 'border-t border-t-gray-300'"
              >
                <app-activity
                  [slot]="slot"
                  [slotIndex]="i"
                  [dayIndex]="dayIndex"
                  [getActivitiEnd]="getActivitiEnd"
                  [slotHeight]="getSlotHeight(slot)"
                  (activitiClick)="activeSlotHandle($event)"
                />
              </div>
            } @else {
              <div
                  [attr.data-time]="timeSlots[i]"
                  [style.gridRow]="slot?.rowspan ? (i + 2) + ' / span ' + slot.rowspan : i+2"
                  [style.gridColumn]="dayIndex + 2"
                  class="p-[3px] bg-transparent border-transparent border-r border-r-gray-300"
                  [class]="$index % 6 !== 0 ? '' : 'border-t border-t-gray-300'"
              >
              </div>
            }
          }
        }
      </div>
    </div>
  </div>
  <dialog class="modal" #modal>
    <div class="modal-box">
      @if (activeSlot) {
        <div class="schedule-modal__content">
          <div class="schedule-modal__left">
            <p>{{ activeSlot!.start + ' - ' + getActivitiEnd(activeSlot!.start, activeSlot!.duration) }}</p>
            <strong>{{ activeSlot!.name }}</strong>
          </div>
          <div class="schedule-modal__right">
            <p>{{ activeSlot!.desc }}</p>
          </div>
        </div>
      }
    </div>
      
    <form method="dialog" class="modal-backdrop">
      <button id="closeModal"></button>
    </form>
  </dialog>
</section>
