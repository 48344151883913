import {
  CommonModule,
  NgOptimizedImage,
  isPlatformBrowser
} from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  InputSignal,
  OnInit,
  PLATFORM_ID,
  WritableSignal,
  effect,
  inject,
  input,
  signal,
  viewChild,
  ChangeDetectionStrategy
} from '@angular/core'

import { GalleryImage } from '@core/models/ui'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-falling-gallery',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './falling-gallery.component.html'
})
export class FallingGalleryComponent implements OnInit, AfterViewInit {
  imagesInput: InputSignal<GalleryImage[]> = input.required()
  private _section = viewChild.required<ElementRef>('section')

  private _platformId = inject(PLATFORM_ID)

  private _isInViewport = signal(false)
  private _loadedImages: WritableSignal<number[]> = signal([])
  private _rotateClasses = signal([
    'rotate-6',
    'rotate-12',
    '-rotate-6',
    '-rotate-12'
  ])
  private _observer!: IntersectionObserver

  constructor() {
    effect(() => {
      if (this.isInViewport) {
        this._observer.unobserve(this.section.nativeElement)
      }
    })
  }

  get images() {
    return this.imagesInput()
  }
  get loadedImages() {
    return this._loadedImages()
  }
  get rotateClasses() {
    return this._rotateClasses()
  }
  get randomRotateClass(): string {
    const randomIndex = Math.floor(Math.random() * this.rotateClasses.length)
    const randomClass = this.rotateClasses[randomIndex]
    this._rotateClasses.update((classes) => [
      ...classes.filter(function (item) {
        return item !== randomClass
      })
    ])

    return randomClass
  }
  get isInViewport() {
    return this._isInViewport()
  }
  get section() {
    return this._section()
  }

  protected isInViewportAndLoaded(index: number) {
    return !(
      (!this.isInViewport && !this.loadedImages.includes(index)) ||
      (!this.isInViewport && this.loadedImages.includes(index)) ||
      (this.isInViewport && !this.loadedImages.includes(index))
    )
  }

  protected addLoadedImage(i: number) {
    this._loadedImages.update((images) => [...images, i])
  }

  ngOnInit() {
    for (const image of this.images) {
      image.rotationClass = this.randomRotateClass
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._observer = new IntersectionObserver(
        ([entry]) => {
          this._isInViewport.set(entry.intersectionRatio >= 0.5)
        },
        {
          root: null,
          threshold: [0.5]
        }
      )

      this._observer.observe(this.section.nativeElement)
    }
  }
}
