<header
  [id]="scroll.headerId"
  [class]="
    [
      'fixed top-0 z-50 flex w-full min-w-80 items-center justify-between px-3 py-2 shadow transition-transform duration-300',
      { 'translate-y-[-100%]': scroll.isHide || !scroll.isLoaded }
    ] | cn
  "
>
  <div
    [class]="
      [
        'absolute left-0 top-0 -z-10 h-full w-full bg-white/50 backdrop-blur-sm duration-300',
        { 'bg-black/50': scroll.isDark }
      ] | cn
    "
  ></div>
  <div class="flex w-full flex-row items-center justify-between gap-2 lg:gap-3">
    <a [routerLink]="lang.homeUrl" class="align-items-center z-30 flex gap-x-2">
      <img
        [ngSrc]="
          scroll.isDark || mobileMenu.visible
            ? 'assets/logo.svg'
            : 'assets/logo-dark-caption.svg'
        "
        [alt]="'Logo ' + siteName"
        class="aspect-[1.04] h-12"
        width="45"
        height="48"
        priority
      />

      <span
        [class]="
          [
            'flex flex-col justify-center text-black',
            {
              'text-white': scroll.isDark || mobileMenu.visible
            }
          ] | cn
        "
      >
        <span class="text-xl font-medium uppercase leading-none">{{
          siteName.split(' ')[0]
        }}</span>
        <span class="text-base uppercase leading-none">{{
          siteName.split(' ')[1]
        }}</span>
      </span>
    </a>
    <nav class="hidden lg:block">
      <ul class="align-items-center under m-0 flex list-none gap-4 p-0">
        @for (item of menuElements; track item.label) {
          <li>
            <a
              [class]="
                [
                  'btn btn-ghost text-black',
                  {
                    'text-white': scroll.isDark,
                    'hover:bg-black/50': scroll.isDark
                  }
                ] | cn
              "
              [routerLink]="item.routerLink"
              routerLinkActive="underline"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              @if (item.icon) {
                <i-lucide [img]="item.icon" />
              }
              <span>{{ item.label }}</span>
            </a>
          </li>
        }
      </ul>
    </nav>
  </div>
  <button
    [class]="
      [
        'btn glass ml-9 hidden font-normal text-black lg:flex',
        {
          'border border-solid border-secondary/50': !scroll.isDark,
          'text-white': scroll.isDark
        }
      ] | cn
    "
    appRipple
  >
    <i-lucide [img]="icons['login']" />
    <span>{{ 'header.login' | translate }}</span>
  </button>

  <div
    class="mr-4 flex w-12 shrink-0 flex-col items-center gap-0 border-r border-secondary/50 pr-2 sm:w-14 sm:pr-4 lg:ml-6 lg:mr-0 lg:border-l lg:border-r-0 lg:pl-4 lg:pr-0"
  >
    @for (language of lang.languages; track language) {
      <button
        [class]="
          [
            'h-6 border border-secondary/0 px-2 py-1',
            {
              'border-secondary/50 bg-black/10': lang.currentLang === language
            }
          ] | cn
        "
        (click)="changeLang(language)"
      >
        <img
          [ngSrc]="'assets/img/flags/' + language + '.svg'"
          [alt]="language"
          class="h-full object-cover"
          width="21"
          height="15"
        />
      </button>
    }
  </div>

  <label
    [class]="
      [
        'btn btn-circle btn-outline swap swap-rotate z-30 rounded-lg text-black lg:hidden',
        {
          'text-white': scroll.isDark,
          'btn-active': mobileMenu.visible
        }
      ] | cn
    "
    appRipple
  >
    <input
      type="checkbox"
      [checked]="mobileMenu.visible"
      (change)="mobileMenu.changeCheckboxEvent($event)"
    />

    <!-- hamburger icon -->
    <svg
      class="swap-off fill-current"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 512 512"
    >
      <path
        d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"
      />
    </svg>

    <!-- close icon -->
    <svg
      class="swap-on fill-current"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 512 512"
    >
      <polygon
        points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"
      />
    </svg>
  </label>

  @if (mobileMenu.visible) {
    <div [class]="['mobile-menu', { 'translate-x-0': mobileMenu.opened }] | cn">
      <div
        class="basis-full overflow-y-auto border-y border-solid border-secondary/40"
      >
        <nav>
          <ul class="m-0 list-none overflow-hidden p-0">
            @for (item of menuElements; track item.label) {
              <li>
                <a
                  appRipple
                  class="btn btn-neutral btn-lg btn-block"
                  [routerLink]="item.routerLink"
                  routerLinkActive="text-secondary"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  @if (item.icon) {
                    <i-lucide [img]="item.icon" class="mr-2" />
                  }
                  <span>{{ item.label }}</span>
                </a>
              </li>
            }
          </ul>
        </nav>
      </div>

      <div class="mt-auto flex flex-col gap-2 p-3">
        <button class="btn btn-accent btn-block">
          <i-lucide [img]="icons['register']" />
          <span>{{ 'header.register' | translate }}</span>
        </button>
        <button class="btn btn-primary btn-block text-white">
          <i-lucide [img]="icons['login']" />
          <span>{{ 'header.login' | translate }}</span>
        </button>
      </div>
    </div>
    <div
      (click)="mobileMenu.change(false)"
      class="fixed left-0 top-0 z-10 h-screen w-full bg-black/10 backdrop-blur-sm lg:hidden"
    ></div>
  }
</header>
