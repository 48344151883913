<app-default-layout page="home" [distanceHeaderBlock]="false">
  <app-sticky-bg
    bgSrcInput="assets/logo-dark-caption.svg"
    [isPriority]="true"
    [width]="585"
    [height]="623"
  >
    <app-video-banner />
    <app-about-us />
  </app-sticky-bg>
  <app-sticky-bg
    bgSrcInput="assets/img/pajewski.webp"
    additionalClass="items-end justify-end"
    additionalImageClass="w-auto h-3/4 opacity-20"
    [width]="411"
    [height]="1078"
  >
    <app-our-team />
    <app-disciplines />
  </app-sticky-bg>
</app-default-layout>
