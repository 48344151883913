@if (breadcrumbs) {
  <div class="custom-scroll breadcrumbs my-2 text-sm">
    <ul>
      @for (
        breadcrumb of breadcrumbs;
        track breadcrumb.name;
        let last = $last
      ) {
        @if (last || !breadcrumb.url) {
          <li>
            <span>{{ breadcrumb.name }}</span>
          </li>
        } @else {
          <li>
            <a [routerLink]="breadcrumb.url">{{ breadcrumb.name }}</a>
          </li>
        }
      }
    </ul>
  </div>
}
