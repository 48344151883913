import {
  Component,
  InputSignal,
  inject,
  input,
  ChangeDetectionStrategy
} from '@angular/core'
import { RouterLink } from '@angular/router'

import { ROUTES_DATA } from '@core/constants/routes.constants'
import { LangService } from '@core/services/lang/lang.service'

export type BreadcrumbsData = {
  name: string
  url?: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent {
  private readonly _homeItem: BreadcrumbsData

  private lang = inject(LangService)

  data: InputSignal<BreadcrumbsData[]> = input.required()

  constructor() {
    this._homeItem = {
      name: ROUTES_DATA['home'][this.lang.currentLang].title,
      url: `/${ROUTES_DATA['home'][this.lang.currentLang].path}`
    }
  }

  get breadcrumbs() {
    const data = this.data()
    if (data && data?.length > 0) {
      return [this._homeItem, ...data]
    }

    return null
  }
}
