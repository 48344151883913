import { isPlatformBrowser } from '@angular/common'

export function stripHtml(htmlAsText: string, platformId: object): string {
  if (isPlatformBrowser(platformId)) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlAsText, 'text/html')

    return doc.body.textContent || ''
  }
  return ''
}
