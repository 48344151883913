import { isPlatformBrowser } from '@angular/common'
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  PLATFORM_ID,
  inject
} from '@angular/core'

@Directive({
  selector: '[appBox]',
  standalone: true
})
export class BoxButtonDirective implements AfterViewInit {
  private _audio!: HTMLAudioElement
  private _platformId = inject(PLATFORM_ID)

  constructor(private _elRef: ElementRef<HTMLElement>) {
    if (isPlatformBrowser(this._platformId)) {
      this._audio = new Audio('assets/sounds/box.mp3')
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    this._audio.currentTime = 0
    this._audio.play()
  }

  ngAfterViewInit() {
    this._elRef.nativeElement.classList.add('box-button')
  }
}
