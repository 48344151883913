<div class="relative min-h-screen">
  <div class="absolute -z-50 size-full">
    <div
      [class]="
        [
          'sticky top-0 flex h-screen w-full items-center justify-center',
          additionalClass()
        ] | cn
      "
    >
      <img
        [class]="
          [
            'max-h-[90vh] max-w-[90vh] object-contain object-center opacity-[0.075]',
            additionalImageClass()
          ] | cn
        "
        [ngSrc]="bgSrc"
        [width]="width()"
        [height]="height()"
        [alt]="siteName + ' - bg image'"
        [priority]="isPriority()"
        [loading]="isPriority() ? undefined : 'lazy'"
      />
    </div>
  </div>

  <ng-content />
</div>
