<section class="mb-14">
  <div class="container">
    <h3
      class="heading-underline-centered mx-auto mb-8 w-fit text-center font-oswald text-3xl font-bold md:text-4xl"
    >
      {{ 'home.disciplines.title' | translate }}
    </h3>
    <div
      class="grid auto-rows-fr grid-cols-1 gap-x-4 gap-y-5 md:grid-cols-2 lg:grid-cols-3"
    >
      @for (discipline of disciplines; track $index) {
        <app-discipline
          [src]="discipline.video"
          [name]="discipline.name"
          [text]="discipline.text"
          [link]="discipline.url"
        />
      }
    </div>
  </div>
</section>
