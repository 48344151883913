<div class="w-full bg-neutral text-neutral-content">
  <div class="container py-6">
    <div class="flex flex-col gap-6 lg:flex-row">
      <div class="flex flex-col gap-2 sm:flex-row lg:flex-col">
        <img
          ngSrc="assets/img/pajewski-team.webp"
          alt="Pajewski Team"
          width="200"
          height="75"
          loading="lazy"
          class="mx-auto h-auto sm:mx-0 lg:mt-1"
        />
        <div
          class="mx-auto flex flex-col gap-1 sm:ml-auto sm:mr-0 lg:ml-0 lg:mt-auto"
        >
          <h5
            class="my-0 text-center text-sm font-medium text-secondary sm:text-left lg:mt-auto"
          >
            {{ 'footer.findUsOn' | translate }}
          </h5>
          <div class="mb-1 flex gap-3">
            @for (social of socials; track social.name) {
              <a
                class="transition-colors hover:text-accent"
                [href]="social.url"
                target="_blank"
                rel="nofollow"
              >
                <social-icon [name]="social.name" />
              </a>
            }
          </div>
        </div>
      </div>

      <div class="border-b border-secondary/50 lg:border-b-0 lg:border-l"></div>

      <div class="w-full">
        <div class="flex flex-col gap-6 sm:flex-row">
          <div class="flex flex-col">
            <div class="flex flex-col gap-4 md:flex-row lg:gap-12 xl:gap-12">
              @for (menu of menus[lang.currentLang]; track menu.name) {
                <div>
                  <h2 [class]="titleClass">{{ menu.name }}</h2>
                  <nav>
                    <ul class="m-0 list-none p-0 text-base">
                      @for (element of menu.elements; track element.label) {
                        <li class="mb-2 text-base leading-5">
                          <a
                            [href]="element.href"
                            class="transition-colors hover:text-accent"
                            [target]="element.target || ''"
                            [rel]="element.ref || ''"
                          >
                            {{ element.label }}
                          </a>
                        </li>
                      }
                    </ul>
                  </nav>
                </div>
              }
            </div>
            <div class="mt-auto pt-4">
              <h2 [class]="titleClass">
                {{ 'footer.downloadApp' | translate }}
              </h2>
              <div class="flex gap-2">
                @for (app of apps; track app.name) {
                  <a [href]="app.url" rel="nofollow" target="_blank">
                    <img
                      [ngSrc]="app.buttonImageSrc"
                      [alt]="app.name"
                      [title]="app.name"
                      [width]="app.width"
                      [height]="app.height"
                      class="h-auto"
                      loading="lazy"
                    />
                  </a>
                }
              </div>
            </div>
          </div>

          <div class="sm:ml-auto">
            <h2 [class]="titleClass">
              {{ 'footer.haveQuestions' | translate }}
            </h2>
            <div>
              @for (contactItem of contact; track contactItem.name) {
                <a
                  class="mb-4 flex items-center gap-2 transition-colors hover:text-accent"
                  [href]="contactItem.href"
                >
                  <span
                    class="rounded-lg border border-solid border-accent p-2 text-accent"
                  >
                    <i-lucide [img]="icons[contactItem.name]" />
                  </span>
                  <span>{{ contactItem.label }}</span>
                </a>
              }
            </div>

            <hr class="my-6 border-secondary/50" />

            <h2 [class]="titleClass">
              {{ 'footer.whereToFindUs' | translate }}
            </h2>
            <address class="mb-3">
              {{ address.street }},<br />{{ address.zip }}&nbsp;{{
                address.city
              }},<br />{{ address.country }}
            </address>
            <a
              appRipple
              class="btn btn-accent flex items-center justify-center gap-2 rounded-lg transition-colors"
              [href]="address.mapUrl"
              target="_blank"
            >
              <i-lucide
                [img]="icons['map']"
                class="text-accent-content transition-colors"
              ></i-lucide>
              <span>{{ 'footer.checkOutMap' | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-6 border-secondary/50" />
    <p class="m-0 text-sm">© {{ currentYear }} {{ siteName }}</p>
  </div>
</div>
