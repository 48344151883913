import { inject } from '@angular/core'
import { CanActivateChildFn } from '@angular/router'

import { AuthService } from '@core/services/auth/auth.service'

export const authGuard: CanActivateChildFn = () => {
  const authService = inject(AuthService)

  if (authService.isLoggedIn() || authService.checkIsLoggedIn()) {
    return true
  }

  authService.redirectToLogin()
  return false
}
