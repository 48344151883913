import { cn } from '@shared/utils/cn'

export const icons: { [key: string]: (className?: string) => string } = {
  facebook: (
    className = ''
  ) => `<svg class="${cn('size-8', className)}" fill="currentColor" viewBox="0 0 800 800">
    <path d="M400,0C180,0,0,180.3,0,402.4s146.4,367.5,337.6,397.6v-281.1h-101.6v-116.5h101.6v-88.8c0-100.8,59.6-156.2,151.2-156.2s89.2,7.6,89.2,7.6v99.2h-50.4c-49.6,0-65.2,30.9-65.2,62.7v75.5h111.2l-18,116.5h-93.2v281.1c94.3-14.9,180.1-63.2,242-136.1,61.9-72.9,95.8-165.6,95.6-261.4C800,180.3,620,0,400,0Z"/>
  </svg>`,
  instagram: (
    className = ''
  ) => `<svg class="${cn('size-8', className)}" fill="currentColor" viewBox="0 0 800 800">
    <g>
      <path style="fill-rule: evenodd;" d="M400,600c110.5,0,200-89.5,200-200s-89.5-200-200-200-200,89.5-200,200,89.5,200,200,200ZM400,533.3c73.6,0,133.3-59.7,133.3-133.3s-59.7-133.3-133.3-133.3-133.3,59.7-133.3,133.3,59.7,133.3,133.3,133.3Z"/>
      <path d="M600,166.7c-18.4,0-33.3,14.9-33.3,33.3s14.9,33.3,33.3,33.3,33.3-14.9,33.3-33.3-14.9-33.3-33.3-33.3Z"/>
      <path style="fill-rule: evenodd;" d="M23.8,119.1C0,165.8,0,226.9,0,349.1v101.8c0,122.2,0,183.3,23.8,230,20.9,41.1,54.3,74.4,95.3,95.3,46.7,23.8,107.8,23.8,230,23.8h101.8c122.2,0,183.3,0,230-23.8,41.1-20.9,74.4-54.3,95.3-95.3,23.8-46.7,23.8-107.8,23.8-230v-101.8c0-122.2,0-183.3-23.8-230-20.9-41.1-54.3-74.4-95.3-95.3C634.2,0,573.1,0,450.9,0h-101.8c-122.2,0-183.3,0-230,23.8-41.1,20.9-74.4,54.3-95.3,95.3ZM450.9,72.7h-101.8c-62.3,0-104.6,0-137.4,2.7-31.9,2.6-48.2,7.3-59.6,13.1-27.4,13.9-49.6,36.2-63.6,63.6-5.8,11.4-10.5,27.7-13.1,59.6-2.7,32.7-2.7,75.1-2.7,137.4v101.8c0,62.3,0,104.6,2.7,137.4,2.6,31.9,7.3,48.2,13.1,59.6,13.9,27.4,36.2,49.6,63.6,63.6,11.4,5.8,27.7,10.5,59.6,13.1,32.7,2.7,75.1,2.7,137.4,2.7h101.8c62.3,0,104.6,0,137.4-2.7,31.9-2.6,48.2-7.3,59.6-13.1,27.4-13.9,49.6-36.2,63.6-63.6,5.8-11.4,10.5-27.7,13.1-59.6,2.7-32.7,2.7-75.1,2.7-137.4v-101.8c0-62.3,0-104.6-2.7-137.4-2.6-31.9-7.3-48.2-13.1-59.6-13.9-27.4-36.2-49.6-63.6-63.6-11.4-5.8-27.7-10.5-59.6-13.1-32.7-2.7-75.1-2.7-137.4-2.7Z"/>
    </g>
  </svg>`,
  whatsapp: (
    className = ''
  ) => `<svg class="${cn('size-8', className)}" fill="currentColor" viewBox="0 0 30.667 30.667" xml:space="preserve">
    <g>
        <path d="M30.667,14.939c0,8.25-6.74,14.938-15.056,14.938c-2.639,0-5.118-0.675-7.276-1.857L0,30.667l2.717-8.017
        c-1.37-2.25-2.159-4.892-2.159-7.712C0.559,6.688,7.297,0,15.613,0C23.928,0.002,30.667,6.689,30.667,14.939z M15.61,2.382
        c-6.979,0-12.656,5.634-12.656,12.56c0,2.748,0.896,5.292,2.411,7.362l-1.58,4.663l4.862-1.545c2,1.312,4.393,2.076,6.963,2.076
        c6.979,0,12.658-5.633,12.658-12.559C28.27,8.016,22.59,2.382,15.61,2.382z M23.214,18.38c-0.094-0.151-0.34-0.243-0.708-0.427
        c-0.367-0.184-2.184-1.069-2.521-1.189c-0.34-0.123-0.586-0.185-0.832,0.182c-0.243,0.367-0.951,1.191-1.168,1.437
        c-0.215,0.245-0.43,0.276-0.799,0.095c-0.369-0.186-1.559-0.57-2.969-1.817c-1.097-0.972-1.838-2.169-2.052-2.536
        c-0.217-0.366-0.022-0.564,0.161-0.746c0.165-0.165,0.369-0.428,0.554-0.643c0.185-0.213,0.246-0.364,0.369-0.609
        c0.121-0.245,0.06-0.458-0.031-0.643c-0.092-0.184-0.829-1.984-1.138-2.717c-0.307-0.732-0.614-0.611-0.83-0.611
        c-0.215,0-0.461-0.03-0.707-0.03S9.897,8.215,9.56,8.582s-1.291,1.252-1.291,3.054c0,1.804,1.321,3.543,1.506,3.787
        c0.186,0.243,2.554,4.062,6.305,5.528c3.753,1.465,3.753,0.976,4.429,0.914c0.678-0.062,2.184-0.885,2.49-1.739
        C23.307,19.268,23.307,18.533,23.214,18.38z"/>
    </g>
  </svg>`
}
