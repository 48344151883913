import { Component, ChangeDetectionStrategy } from '@angular/core'

import { StickyBgComponent } from '@features/sticky-bg/sticky-bg.component'
import { DefaultLayoutComponent } from '@layouts/default/default.component'

import { ContentComponent } from './content/content.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-timetable',
  standalone: true,
  imports: [
    DefaultLayoutComponent,
    StickyBgComponent,
    ContentComponent
  ],
  templateUrl: './timetable.component.html'
})
export class TimetableComponent {
}
