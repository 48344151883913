import { isPlatformBrowser } from '@angular/common'
import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { stripHtml } from '@shared/utils/stripHtml/stripHtml.util'

@Pipe({
  name: 'textWithUnderlines',
  standalone: true
})
export class TextWithUnderlinesPipe implements PipeTransform {
  private _platformId = inject(PLATFORM_ID)
  private _sanitizer = inject(DomSanitizer)

  transform(text: string, underlineWords: string[]) {
    if (!isPlatformBrowser(this._platformId)) {
      return ''
    }

    const striped = stripHtml(text, this._platformId)
    const regex = new RegExp(`\\b(${underlineWords.join('|')})\\b`, 'gi')

    const replacedText = striped.replace(regex, (match: string) => {
      return `<span class="painted-underline">${match}</span>`
    })

    return this._sanitizer.bypassSecurityTrustHtml(replacedText)
  }
}
