import { Component, ChangeDetectionStrategy } from '@angular/core'

import { AboutUsComponent } from '@features/about-us/about-us.component'
import { DisciplinesComponent } from '@features/disciplines/disciplines.component'
import { OurTeamComponent } from '@features/our-team/our-team.component'
import { StickyBgComponent } from '@features/sticky-bg/sticky-bg.component'
import { VideoBannerComponent } from '@features/video-banner/video-banner.component'
import { DefaultLayoutComponent } from '@layouts/default/default.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-home',
  standalone: true,
  imports: [
    DefaultLayoutComponent,
    VideoBannerComponent,
    AboutUsComponent,
    StickyBgComponent,
    OurTeamComponent,
    DisciplinesComponent
  ],
  templateUrl: './home.component.html'
})
export class HomeComponent {}
