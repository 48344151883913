import { isPlatformBrowser } from '@angular/common'
import { Injectable, PLATFORM_ID, inject, signal } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private _platformId = inject(PLATFORM_ID)

  private _isDark = signal(false)
  private _isHide = signal(true)
  private _isLoaded = signal(false)

  private _lastScrollY = 0
  private _delta = 0

  private readonly _onScrollHandler: () => void
  private readonly _darkHeaderObserve?: IntersectionObserver

  headerId = 'top-header'
  hideThreshold = 50
  showThreshold = 20

  get isLoaded() {
    return this._isLoaded()
  }

  get isDark() {
    return this._isDark()
  }

  get isHide() {
    return this._isHide()
  }

  constructor() {
    this._onScrollHandler = this._onScroll.bind(this)

    if (isPlatformBrowser(this._platformId)) {
      this._darkHeaderObserve = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.isIntersecting) {
              this._isDark.set(true)
            } else {
              this._isDark.set(false)
            }
          }

          this._isLoaded.set(true)
        },
        {
          root: null,
          threshold: 0.01
        }
      )
    }
  }

  init() {
    if (isPlatformBrowser(this._platformId)) {
      window.addEventListener('scroll', this._onScrollHandler)
    }
  }

  afterViewInit() {
    this._initDarkHeaderFlag()
    this._isHide.set(false)
  }

  destroy() {
    if (isPlatformBrowser(this._platformId)) {
      this._destroyDarkHeaderFlag()
      window.removeEventListener('scroll', this._onScrollHandler)
    }
  }

  private _onScroll() {
    const currentScrollY = window.scrollY

    if (this._lastScrollY > 0) {
      this._delta += currentScrollY - this._lastScrollY

      if (this._delta > this.hideThreshold) {
        this._isHide.set(true)
        this._delta = 0
      } else if (this._delta < -this.showThreshold) {
        this._isHide.set(false)
        this._delta = 0
      }
    }

    this._lastScrollY = currentScrollY
  }

  private _initDarkHeaderFlag() {
    if (!this._darkHeaderObserve) return

    const header = document.getElementById(this.headerId)
    const darkSections = document.querySelectorAll('[data-header-dark]')

    if (header && darkSections?.length > 0) {
      for (const section of darkSections) {
        this._darkHeaderObserve.observe(section)
      }
    } else {
      this._isLoaded.set(true)
    }
  }

  private _destroyDarkHeaderFlag() {
    if (!this._darkHeaderObserve) return

    this._darkHeaderObserve.disconnect()
  }
}
