import {
  Component,
  InputSignal,
  OnInit,
  WritableSignal,
  inject,
  input,
  signal,
  ChangeDetectionStrategy
} from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { icons } from '@shared/components/social-icon/icons'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'social-icon',
  standalone: true,
  template: `<span [innerHTML]="svgIcon"></span>`
})
export class SocialIconComponent implements OnInit {
  private _sanitizer = inject(DomSanitizer)
  private _svgIcon: WritableSignal<SafeUrl> = signal('')

  name: InputSignal<string> = input.required()
  customClass: InputSignal<string> = input('')

  protected get svgIcon() {
    return this._svgIcon()
  }

  private _setIcon() {
    const icon = icons[this.name()]
    if (!icon) {
      console.warn(`Ikona o nazwie "${this.name()}" nie została znaleziona.`)
      return
    }

    this._svgIcon.set(
      this._sanitizer.bypassSecurityTrustHtml(icon(this.customClass()))
    )
  }

  ngOnInit() {
    this._setIcon()
  }
}
