import { AddressData, ContactData, SocialsData } from '@core/models/contact'

export const contactData: ContactData[] = [
  {
    name: 'phone',
    label: '+48 889 233 159',
    href: 'tel:+48889233159'
  },
  {
    name: 'mail',
    label: 'kswszczecinek.1991@gmail.com',
    href: 'mailto:kswszczecinek.1991@gmail.com'
  }
]

export const addressData: AddressData = {
  street: 'Wiatraczna 5',
  city: 'Szczecinek',
  zip: '78-400',
  country: 'Polska',
  mapUrl:
    'https://www.google.com/maps/place//data=!4m2!3m1!1s0x47018526b8ad4f5b:0x59bf3c447f6cbf32?sa=X&ved=1t:8290&hl=pl-PL&ictx=111'
}

export const socialsData: SocialsData[] = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/ksw.zsz'
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/ksw.szczecinek/'
  },
  {
    name: 'whatsapp',
    url: 'https://wa.link/zz57nu'
  }
]
