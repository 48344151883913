import { CommonModule } from '@angular/common'
import { Component, inject, ChangeDetectionStrategy } from '@angular/core'
import { RouterLink } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { LucideAngularModule, MoveRight } from 'lucide-angular'

import { ROUTES_DATA } from '@core/constants/routes.constants'
import { GalleryImage, IconRegistry } from '@core/models/ui'
import { LangService } from '@core/services/lang/lang.service'
import { FallingGalleryComponent } from '@features/falling-gallery/falling-gallery.component'
import { BoxButtonDirective } from '@shared/directives/box-button/box-button.directive'
import { RippleDirective } from '@shared/directives/ripple/ripple.directive'
import { Nl2brPipe } from '@shared/pipes/nl2br/nl2br.pipe'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-about-us',
  standalone: true,
  imports: [
    TranslateModule,
    Nl2brPipe,
    LucideAngularModule,
    CommonModule,
    RippleDirective,
    BoxButtonDirective,
    FallingGalleryComponent,
    RouterLink
  ],
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent {
  protected lang = inject(LangService)

  protected readonly readMoreLink: string
  protected readonly icons: IconRegistry = {
    moveRight: MoveRight
  }
  protected galleryImages: GalleryImage[] = [
    {
      caption: 'About us 1',
      url: 'assets/img/as/aboutus1.webp',
      rotationClass: ''
    },
    {
      caption: 'About us 2',
      url: 'assets/img/as/aboutus2.webp',
      rotationClass: ''
    },
    {
      caption: 'About us 3',
      url: 'assets/img/as/aboutus3.webp',
      rotationClass: ''
    },
    {
      caption: 'About us 4',
      url: 'assets/img/as/aboutus4.webp',
      rotationClass: ''
    }
  ]

  constructor() {
    this.readMoreLink = `/${ROUTES_DATA['aboutUs'][this.lang.currentLang].path}`
  }
}
