import { HttpClient } from '@angular/common/http'
import { Injectable, inject, signal } from '@angular/core'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { Observable, tap } from 'rxjs'

import { AuthApiResponse } from '@core/models/api'
import { LangService } from '@core/services/lang/lang.service'
import { environment } from '@environments/environment'

type LoginFormDataType = {
  email?: string | null
  password?: string | null
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient)
  private router = inject(Router)
  private cookie = inject(CookieService)
  private lang = inject(LangService)

  private _accessToken = signal<string | null>(null)
  private _refreshToken = signal<string | null>(null)
  private _permissions = signal<string | null>(null)
  private _firstName = signal<string | null>(null)
  private _isLoggedIn = signal<boolean>(false)

  isLoggedIn = this._isLoggedIn.asReadonly()
  firstName = this._firstName.asReadonly()
  accessToken = this._accessToken.asReadonly()

  constructor() {
    this.checkIsLoggedIn()
  }

  checkIsLoggedIn(): boolean {
    this.getAccessToken()

    const hasTokens = !!this._accessToken() || !!this._refreshToken()

    this._isLoggedIn.set(hasTokens)

    return hasTokens
  }

  getAccessToken(): void {
    if (!this._accessToken()) {
      this._accessToken.set(this.cookie.get('accessToken'))
      this._refreshToken.set(this.cookie.get('refreshToken'))
      this._permissions.set(this.cookie.get('permissions'))
      this._firstName.set(this.cookie.get('firstname'))
    }
  }

  saveTokens({
    accessToken,
    refreshToken,
    expiresIn,
    refreshExpiresIn,
    firstName,
    permissions
  }: AuthApiResponse): void {
    const expiresDate = this.getExpiresDateBySeconds(expiresIn)
    const refreshExpiresDate = this.getExpiresDateBySeconds(refreshExpiresIn)

    this.cookie.set('accessToken', accessToken, expiresDate, '/')
    this.cookie.set('refreshToken', refreshToken, refreshExpiresDate, '/')
    this.cookie.set('firstName', firstName, refreshExpiresDate, '/')
    this.cookie.set('permissions', permissions, refreshExpiresDate, '/')

    this.checkIsLoggedIn()
  }

  refreshToken(): Observable<AuthApiResponse> {
    return this.http
      .post<AuthApiResponse>(`${environment.apiUrl}/auth/refresh`, {
        refreshToken: this._refreshToken()
      })
      .pipe(
        tap((response) => {
          this.saveTokens(response)
        })
      )
  }

  login(credentials: LoginFormDataType) {
    return this.http
      .post<AuthApiResponse>(`${environment.apiUrl}/auth/login`, credentials)
      .pipe(
        tap((response) => {
          this.saveTokens(response)

          this.router.navigateByUrl(`${this.lang.homeUrl}/panel`)
        })
      )
  }

  logout() {
    this.cookie.delete('accessToken', '/')
    this.cookie.delete('refreshToken', '/')
    this.cookie.delete('firstName', '/')
    this.cookie.delete('permissions', '/')

    this._accessToken.set(null)
    this._refreshToken.set(null)
    this._permissions.set(null)
    this._firstName.set(null)

    this._isLoggedIn.set(false)
    this._firstName.set(null)

    this.redirectToLogin()
  }

  redirectToLogin() {
    this.router.navigateByUrl(this.lang.homeUrl)
  }

  private getExpiresDateBySeconds(seconds: number): Date {
    const d = new Date()
    d.setTime(d.getTime() + seconds * 1000)

    return d
  }
}
