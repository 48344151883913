import { Injectable, inject, signal } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, take } from 'rxjs'

import {
  DEFAULT_LANGUAGE,
  Lang,
  ROUTES_DATA
} from '@core/constants/routes.constants'

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private activatedRoute = inject(ActivatedRoute)
  private router = inject(Router)

  private _currentLang = signal(DEFAULT_LANGUAGE)
  private _homeUrl = signal('/')

  readonly languages: Lang[] = Object.values(Lang)

  get currentLang() {
    return this._currentLang()
  }
  get homeUrl() {
    return this._homeUrl()
  }

  routerDataAndParams$ = combineLatest({
    data: this.activatedRoute.data,
    params: this.activatedRoute.params
  })

  constructor() {
    this.routerDataAndParams$
      .pipe(takeUntilDestroyed())
      .subscribe(({ data: { lang }, params: { lang: paramsLang } }) => {
        const currentLang = lang || paramsLang || DEFAULT_LANGUAGE

        this._currentLang.set(currentLang)
        this._homeUrl.set(
          currentLang === DEFAULT_LANGUAGE ? '/' : `/${currentLang}`
        )
      })
  }

  changeLanguage(newLang: Lang) {
    this.routerDataAndParams$
      .pipe(take(1))
      .subscribe(({ data: { name }, params: { lang: paramsLang } }) => {
        try {
          if (name) {
            return this.router.navigateByUrl(ROUTES_DATA[name][newLang].path)
          }

          const path = paramsLang
            ? this.router.url.replace(`/${paramsLang}`, '')
            : this.router.url

          return this.router.navigateByUrl(
            newLang === DEFAULT_LANGUAGE ? path : `/${newLang}${path}`
          )
        } catch (e) {
          console.error('Error changing language', e)
          return this.router.navigateByUrl(`/${newLang}`)
        }
      })
  }
}
