import { Component, ChangeDetectionStrategy } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { BicepsFlexed, LucideAngularModule } from 'lucide-angular'

import { IconRegistry } from '@core/models/ui'
import { TextWithUnderlinesPipe } from '@shared/pipes/text-with-underlines/text-with-underlines.pipe'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-video-banner',
  standalone: true,
  imports: [TranslateModule, LucideAngularModule, TextWithUnderlinesPipe],
  templateUrl: './video-banner.component.html'
})
export class VideoBannerComponent {
  protected underlineWords = ['motywujące', 'motivational']
  protected poster = 'assets/img/poster.webp'
  protected videoSrc = 'assets/videos/banner.mp4'

  protected readonly icons: IconRegistry = {
    register: BicepsFlexed
  }
}
