import {
  Component,
  ChangeDetectionStrategy,
  inject,
  WritableSignal,
  signal
} from '@angular/core'
import {
  LucideAngularModule,
  TriangleAlert,
  Info,
  CircleCheckBig
} from 'lucide-angular'

import { ToastMessage, ToastType } from '@core/models/toast'
import { IconRegistry } from '@core/models/ui'
import { ScrollService } from '@core/services/scroll/scroll.service'
import { ToastService } from '@core/services/toast/toast.service'
import { CnPipe } from '@shared/pipes/cn/cn.pipe'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toast',
  standalone: true,
  imports: [CnPipe, LucideAngularModule],
  templateUrl: './toast.component.html'
})
export class ToastComponent {
  scroll = inject(ScrollService)
  toast = inject(ToastService)

  icons: IconRegistry = {
    [ToastType.Success]: CircleCheckBig,
    [ToastType.Info]: Info,
    [ToastType.Warning]: TriangleAlert,
    [ToastType.Error]: CircleCheckBig
  }

  toasts: WritableSignal<ToastMessage[]> = signal([])

  protected readonly ToastType = ToastType

  constructor(private toastService: ToastService) {
    this.toast.toasts$.subscribe((items) => {
      this.toasts.set(items)
    })
  }

  get toastsList() {
    return this.toasts()
  }

  removeToast(id: number) {
    this.toastService.removeToast(id)
  }
}
