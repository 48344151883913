<iframe
  [src]="mapSrc"
  [class]="
    [
      'sticky top-4 min-h-96 w-full duration-300',
      {
        'top-20': !scroll.isHide
      }
    ] | cn
  "
  style="border: 0"
  allowfullscreen=""
  loading="lazy"
  referrerpolicy="no-referrer-when-downgrade"
></iframe>
