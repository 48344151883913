<div
  class="relative -z-10 mx-auto h-[100svw] max-h-[450px] w-full max-w-[450px] md:h-full md:min-h-0 md:max-w-[700px]"
  #section
>
  @for (image of images; track image.caption; let i = $index) {
    <!-- TODO: sprawdzic czy mozna [class] i ngClass polaczyc -->
    @if (i === 0) {
      <img
        [ngSrc]="image.url"
        class="falling-gallery-image shadow-md"
        [ngClass]="{
          'rotate-0 scale-150 opacity-0': !isInViewportAndLoaded(i),
          'scale-1 opacity-100': isInViewportAndLoaded(i)
        }"
        [class]="image.rotationClass || ''"
        (load)="addLoadedImage(i)"
        [alt]="image.caption"
        fill
        priority
      />
    } @else {
      <img
        [ngSrc]="image.url"
        class="falling-gallery-image shadow-md"
        [ngClass]="{
          'delay-[1500ms]': i === 1,
          'delay-[3000ms]': i === 2,
          'delay-[4500ms]': i === 3,
          'delay-[6000ms]': i === 4,
          'rotate-0 scale-150 opacity-0': !isInViewportAndLoaded(i),
          'scale-1 opacity-100': isInViewportAndLoaded(i)
        }"
        [class]="image.rotationClass || ''"
        (load)="addLoadedImage(i)"
        [alt]="image.caption"
        fill
      />
    }
  }
</div>
