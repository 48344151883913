import { HttpClient } from '@angular/common/http'
import { Component, ChangeDetectionStrategy } from '@angular/core'

import { environment } from '@environments/environment'
import { PanelLayoutComponent } from '@layouts/panel/panel.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-panel-home',
  standalone: true,
  imports: [PanelLayoutComponent],
  templateUrl: './home.component.html'
})
export class PanelHomeComponent {
  constructor(private http: HttpClient) {
    this.http.get(`${environment.apiUrl}/profile`).subscribe(console.log)
  }
}
