import { Component, ChangeDetectionStrategy } from '@angular/core'

import { StickyBgComponent } from '@features/sticky-bg/sticky-bg.component'
import { DefaultLayoutComponent } from '@layouts/default/default.component'

import { ContentComponent } from './content/content.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [DefaultLayoutComponent, ContentComponent, StickyBgComponent],
  templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent {}
