import { Component, inject, ChangeDetectionStrategy } from '@angular/core'

import { LangService } from '@core/services/lang/lang.service'
import { BreadcrumbsComponent } from '@features/breadcrumbs/breadcrumbs.component'
import { ScheduleComponent } from '@features/schedule/schedule.component'

type SinglePageContent = {
  title: string
  content: string
}

const MOCK_DATA: { [key: string]: SinglePageContent } = {
  pl: {
    title: 'Grafik',
    content:
      '<p>Grafik pl...</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.\n\nCras viverra nunc fringilla odio faucibus congue. Vivamus mollis bibendum sapien, at interdum dui ultricies bibendum. Mauris sollicitudin vestibulum diam at mollis. Integer turpis orci, ultricies ut nulla sit amet, ultricies mollis eros. Maecenas vulputate mauris sed ex elementum tincidunt. Donec aliquam quis massa vel ornare. Sed non turpis est. Vestibulum eros lacus, semper sit amet tempor nec, cursus ut magna. Proin sodales, nibh in congue suscipit, nibh urna tincidunt leo, eu varius urna nisl at purus. Vivamus dignissim, augue sit amet pretium ultricies, ligula odio dictum massa, id mattis mi neque sit amet dolor.</p>'
  },
  en: {
    title: 'Schedule',
    content:
      '<p>Timetable en...</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.\n\nCras viverra nunc fringilla odio faucibus congue. Vivamus mollis bibendum sapien, at interdum dui ultricies bibendum. Mauris sollicitudin vestibulum diam at mollis. Integer turpis orci, ultricies ut nulla sit amet, ultricies mollis eros. Maecenas vulputate mauris sed ex elementum tincidunt. Donec aliquam quis massa vel ornare. Sed non turpis est. Vestibulum eros lacus, semper sit amet tempor nec, cursus ut magna. Proin sodales, nibh in congue suscipit, nibh urna tincidunt leo, eu varius urna nisl at purus. Vivamus dignissim, augue sit amet pretium ultricies, ligula odio dictum massa, id mattis mi neque sit amet dolor.</p>'
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-content-timetable',
  standalone: true,
  templateUrl: './content.component.html',
  imports: [BreadcrumbsComponent, ScheduleComponent]
})
export class ContentComponent {
  private readonly _lang = inject(LangService)

  get data() {
    return MOCK_DATA[this._lang.currentLang]
  }
}
