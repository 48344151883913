<div class="min-h-screen overflow-hidden py-16 lg:py-32">
  <div class="container">
    <div class="grid grid-cols-1 items-center gap-4 py-5 md:grid-cols-2">
      <div class="order-1 max-w-[655px] pt-10 md:pt-0">
        <h2
          class="heading-underline font-oswald text-3xl font-bold md:text-4xl"
        >
          {{ 'home.aboutUs.title' | translate }}
        </h2>
        <p
          class="mb-5 text-lg text-shadow-light md:text-xl"
          [innerHTML]="'home.aboutUs.content' | translate | nl2br"
        ></p>
        <a appRipple class="btn" appBox [routerLink]="readMoreLink">
          <span>{{ 'global.readMore' | translate }}</span>
          <i-lucide [img]="icons['moveRight']"></i-lucide>
        </a>
      </div>
      <div class="md:order-2">
        <app-falling-gallery
          [imagesInput]="galleryImages"
        ></app-falling-gallery>
      </div>
    </div>
  </div>
</div>
