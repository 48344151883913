import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { AuthService } from '@core/services/auth/auth.service'
import { LangService } from '@core/services/lang/lang.service'
import { SeoService } from '@core/services/seo/seo.service'
import { FooterComponent } from '@features/footer/footer.component'
import { HeaderComponent } from '@features/header/header.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-panel-layout',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './panel.component.html',
  providers: [LangService, AuthService]
})
export class PanelLayoutComponent implements OnInit {
  @Input({ required: true }) title!: string

  constructor(
    private seoService: SeoService,
    private translate: TranslateService,
    private lang: LangService
  ) {
    this.translate.use(this.lang.currentLang)
  }

  ngOnInit() {
    this.seoService.setRawMetaTags(this.title)
  }
}
