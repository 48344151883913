import { CommonModule, NgOptimizedImage } from '@angular/common'
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  inject,
  signal,
  ChangeDetectionStrategy
} from '@angular/core'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { LogIn, LucideAngularModule, UserPlus } from 'lucide-angular'
import { LucideIconData } from 'node_modules/lucide-angular/icons/types'

import { Lang, ROUTES_DATA } from '@core/constants/routes.constants'
import { IconRegistry } from '@core/models/ui'
import { LangService } from '@core/services/lang/lang.service'
import { ScrollService } from '@core/services/scroll/scroll.service'
import { environment } from '@environments/environment'
import { RippleDirective } from '@shared/directives/ripple/ripple.directive'
import { CnPipe } from '@shared/pipes/cn/cn.pipe'

import { MobileMenuService } from './services/mobile-menu/mobile-menu.service'

type HeaderItemType = {
  label: string
  routerLink: string
  icon?: LucideIconData
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    LucideAngularModule,
    RippleDirective,
    CnPipe
  ],
  templateUrl: './header.component.html',
  providers: [MobileMenuService]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  // private _translate = inject(TranslateService)
  // private _auth = inject(AuthService)
  protected lang = inject(LangService)
  protected scroll = inject(ScrollService)
  protected mobileMenu = inject(MobileMenuService)

  private _menuElements: WritableSignal<HeaderItemType[]> = signal([])

  // protected profileMenuItems = []
  // protected authModalRef = null
  protected readonly siteName = environment.siteName
  protected readonly icons: IconRegistry = {
    login: LogIn,
    register: UserPlus
  }

  get menuElements() {
    return this._menuElements()
  }

  ngOnInit() {
    this.scroll.init()
    this._loadMenuElements()
  }

  ngAfterViewInit() {
    this.scroll.afterViewInit()
  }

  ngOnDestroy() {
    this.scroll.destroy()
  }

  changeLang(lang: Lang) {
    return this.lang.changeLanguage(lang)
  }

  private _loadMenuElements() {
    const lang = this.lang.currentLang
    const menuElements = ['home', 'aboutUs', 'timetable', 'contact']
      .map((item) => this._loadHeaderItem(item, lang))
      .filter((item) => item)

    this._menuElements.set(menuElements)
  }

  private _loadHeaderItem(item: string, lang: Lang): HeaderItemType {
    return (
      ROUTES_DATA[item] && {
        label: ROUTES_DATA[item][lang].title,
        routerLink: `/${ROUTES_DATA[item][lang].path}`
      }
    )
  }

  // private _loadProfileMenuItems() {
  //   combineLatest({
  //     profileLabel: this._translate.get('loggedIn.profile'),
  //     logoutLabel: this._translate.get('loggedIn.logout')
  //   }).subscribe(({ profileLabel, logoutLabel }) => {
  //     this.profileMenuItems = [
  //       {
  //         label: profileLabel,
  //         routerLink: `${this._lang.homeUrl()}/panel`,
  //         icon: 'pi pi-user',
  //         routerLinkActiveOptions: { exact: true }
  //       },
  //       {
  //         label: logoutLabel,
  //         command: () => this._auth.logout(),
  //         icon: 'pi pi-sign-out',
  //         routerLinkActiveOptions: { exact: true }
  //       }
  //     ] as never
  //   })
  // }

  // openLoginModal() {
  //   this.authModalRef = null
  // }
}
