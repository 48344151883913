import {
  AfterViewInit,
  Component,
  WritableSignal,
  inject,
  signal,
  ChangeDetectionStrategy
} from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

import { ScrollService } from '@core/services/scroll/scroll.service'
import { CnPipe } from '@shared/pipes/cn/cn.pipe'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-map',
  standalone: true,
  imports: [CnPipe],
  templateUrl: './map.component.html'
})
export class MapComponent implements AfterViewInit {
  private _sanitizer = inject(DomSanitizer)
  protected scroll = inject(ScrollService)
  private _mapSrc: WritableSignal<SafeUrl> = signal('')
  get mapSrc(): SafeUrl {
    return this._mapSrc()
  }

  ngAfterViewInit() {
    const url =
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2361.0837480225773!2d16.682742176985585!3d53.716770446942505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47018526b8ad4f5b%3A0x59bf3c447f6cbf32!2sKlub%20Sport%C3%B3w%20Walki%20Szczecinek!5e0!3m2!1spl!2spl!4v1736249616856!5m2!1spl!2spl'
    const safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(url)

    this._mapSrc.set(safeURL)
  }
}
