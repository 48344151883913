<ng-container>
  <app-breadcrumbs [data]="[{ name: pageName }]" />
  <div class="grid grid-cols-1 gap-8 xl:grid-cols-2">
    <div class="w-full xl:ml-auto xl:max-w-[661px]">
      <h1 class="heading-underline font-oswald text-3xl font-bold md:text-4xl">
        {{ 'home.contactUs.title' | translate }}
      </h1>
      <div class="mb-10 flex flex-col md:flex-row">
        <div class="basis-full">
          <h3 class="mb-3 mt-0 text-xl font-medium text-secondary">
            {{ 'footer.whereToFindUs' | translate }}
          </h3>
          <address class="font-semibold">
            {{ address.street }},<br />{{ address.zip }}&nbsp;{{
              address.city
            }},<br />{{ address.country }}
          </address>
          <a
            class="btn-danger btn btn-outline mt-4 shadow-md"
            appRipple="rgba(255,255,255,0.35)"
            appBox
            [routerLink]="scheduleLink"
          >
            <i-lucide [img]="icons['biceps']"></i-lucide>
            <span>{{ 'home.contactUs.scheduleButton' | translate }}</span>
          </a>
        </div>
        <div class="flex">
          <div class="divider md:divider-horizontal"></div>
        </div>
        <div class="flex basis-full flex-col gap-6">
          @for (contactItem of contact; track $index) {
            <div>
              <h3
                class="mb-2 mt-0 flex items-center gap-2 text-xl font-medium text-secondary"
              >
                <span class="rounded-lg p-1 text-secondary">
                  <i-lucide [img]="icons[contactItem.name]" />
                </span>
                {{ 'home.contactUs.' + contactItem.name | translate }}
              </h3>
              <a
                [href]="contactItem.href"
                class="font-semibold italic transition-colors hover:text-primary"
                appRipple
              >
                <span class="p-1">{{ contactItem.label }}</span>
              </a>
            </div>
          }
        </div>
      </div>
      <div class="w-full">
        <div class="divider">{{ 'home.contactUs.or' | translate }}</div>
      </div>
      <form class="form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <h2 class="mb-2 block font-oswald text-2xl md:text-3xl">
          {{ 'home.contactUs.sendMessage' | translate }}
        </h2>
        <p class="label-text mb-5">
          <b>
            <span class="text-error">*</span> -
            {{ 'home.contactUs.requiredFields' | translate }}
          </b>
        </p>
        <div class="grid md:grid-cols-2 md:gap-5">
          <app-input
            [label]="'home.contactUs.form.name' | translate"
            [control]="getAsFormControl('name')"
          />
          <app-input
            [label]="'home.contactUs.form.surname' | translate"
            [control]="getAsFormControl('surname')"
          />
        </div>
        <div class="grid md:grid-cols-2 md:gap-5">
          <app-input
            type="email"
            [label]="'home.contactUs.form.mail' | translate"
            [control]="getAsFormControl('mail')"
          />
          <app-input
            type="phone"
            [label]="'home.contactUs.form.phone' | translate"
            [control]="getAsFormControl('phone')"
          />
        </div>
        <div>
          <app-input
            [label]="'home.contactUs.form.subject' | translate"
            [control]="getAsFormControl('subject')"
          />
        </div>
        <div>
          <app-textarea
            [label]="'home.contactUs.form.message' | translate"
            [control]="getAsFormControl('message')"
          />
        </div>
        <div class="mb-5 flex flex-col">
          <app-checkbox
            [control]="getAsFormControl('terms')"
            [text]="checkboxText"
          />
        </div>
        <button
          [disabled]="contactForm.invalid"
          type="submit"
          class="btn btn-outline btn-neutral my-5 h-16"
          appRipple
        >
          <span>{{ 'home.contactUs.sendMessage' | translate }}</span>
        </button>
      </form>
    </div>
    <app-map />
  </div>
</ng-container>
