import { AsyncPipe } from '@angular/common'
import {
  Component,
  ChangeDetectionStrategy,
  InputSignal,
  input
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'

import { ErrorMessagesService } from '@core/services/error-messages/error-messages.service'

@Component({
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-error-messages',
  standalone: true,
  templateUrl: './error-messages.component.html',
  imports: [AsyncPipe]
})
export class ErrorMessagesComponent {
  control: InputSignal<FormControl> = input.required()

  constructor(private errorMessagesService: ErrorMessagesService) {}

  get hasError() {
    return this.control()?.invalid && this.control()?.touched
  }

  get errorMessage(): Observable<string> | null {
    return this.hasError
      ? this.errorMessagesService.getErrorMessage(this.control())
      : null
  }
}
