import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { stripHtml } from '@shared/utils/stripHtml/stripHtml.util'

@Pipe({
  name: 'nl2br',
  standalone: true
})
export class Nl2brPipe implements PipeTransform {
  private _sanitizer = inject(DomSanitizer)
  private _platformId = inject(PLATFORM_ID)

  transform(value: string) {
    if (!value) {
      return ''
    }

    const stripped = stripHtml(value, this._platformId)
    const newValue = stripped.replace(/\n/g, '<br>')

    return this._sanitizer.bypassSecurityTrustHtml(newValue)
  }
}
