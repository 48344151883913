import { Route, Routes } from '@angular/router'

import { Lang, ROUTES } from '@core/constants/routes.constants'
import { authGuard } from '@core/guards/auth/auth.guard'
import { PanelHomeComponent } from '@pages/panel/home/home.component'

const generateMultilanguageRoute = (name: string): Routes =>
  ROUTES[name]
    ? [
        {
          data: { name },
          path: ROUTES[name].pl.path,
          component: ROUTES[name].component
        },
        {
          data: { name, lang: Lang.EN },
          path: ROUTES[name].en.path,
          component: ROUTES[name].component
        }
      ]
    : []

const panelRoutes: Route = {
  path: 'panel',
  canActivateChild: [authGuard],
  children: [
    {
      path: '',
      component: PanelHomeComponent
    }
  ]
}

export const routes: Routes = [
  ...generateMultilanguageRoute('home'),
  ...generateMultilanguageRoute('aboutUs'),
  ...generateMultilanguageRoute('timetable'),
  ...generateMultilanguageRoute('contact'),
  ...generateMultilanguageRoute('termsAndConditions'),
  ...generateMultilanguageRoute('privacyPolicy'),
  panelRoutes,
  {
    path: ':lang',
    children: [panelRoutes]
  },
  { path: '**', redirectTo: '/' } // @TODO: Add component for 404 page where user can go back to home page with the same language
]
