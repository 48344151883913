
import { Component, InputSignal, input, output, ChangeDetectionStrategy } from '@angular/core'
import { ActivityClickEmitI, IActivity } from '@core/models/schedule'
import { RippleDirective } from '@shared/directives/ripple/ripple.directive'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-activity',
  standalone: true,
  imports: [RippleDirective],
  templateUrl: './activity.component.html'
})
export class ActivityComponent {
  activitiClick = output<ActivityClickEmitI>()

  slot: InputSignal<IActivity> = input.required()
  slotIndex: InputSignal<number> = input.required()
  dayIndex: InputSignal<number> = input.required()
  slotHeight: InputSignal<number> = input.required()
  getActivitiEnd: InputSignal<(start: string, duration: string) => string> =
    input.required()

  get slotValue() {
    return this.slot()
  }

  get getActivitiEndFunc() {
    return this.getActivitiEnd()
  }

  protected emitClick(): void {
    this.activitiClick.emit({
      slotIndex: this.slotIndex(),
      dayIndex: this.dayIndex()
    })
  }
}
