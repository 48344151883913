import {
  Component,
  WritableSignal,
  signal,
  ChangeDetectionStrategy
} from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

import { DisciplineComponent } from '@features/disciplines/discipline/discipline.component'

type Discipline = {
  name: string
  video: string
  text: string
  url: string
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-disciplines',
  standalone: true,
  imports: [TranslateModule, DisciplineComponent],
  templateUrl: './disciplines.component.html'
})
export class DisciplinesComponent {
  private _disciplines: WritableSignal<Discipline[]> = signal([
    {
      name: 'Kickboxing',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    },
    {
      name: 'Kickboxing 2',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    },
    {
      name: 'Kickboxing 3',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    },
    {
      name: 'Kickboxing 4',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    },
    {
      name: 'Kickboxing 5',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    },
    {
      name: 'Kickboxing 6',
      video: 'assets/videos/kickboxing.mp4',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor libero quis commodo efficitur. Sed sed sollicitudin eros, ut porta felis. Integer id nisi eu est lobortis dignissim. Cras vehicula lacus in augue interdum tempor. Sed eu ante in nisi tincidunt porttitor. In hac habitasse platea dictumst. Nunc at iaculis massa, a laoreet massa. Duis rhoncus scelerisque lectus a consectetur. Sed eu fermentum felis, at pulvinar dolor.',
      url: ''
    }
  ])

  get disciplines() {
    return this._disciplines()
  }
}
