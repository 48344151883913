import { Component, ChangeDetectionStrategy } from '@angular/core'

import { StickyBgComponent } from '@features/sticky-bg/sticky-bg.component'
import { DefaultLayoutComponent } from '@layouts/default/default.component'

import { ContentComponent } from './content/content.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [DefaultLayoutComponent, ContentComponent, StickyBgComponent],
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {}
