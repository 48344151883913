import { KeyValuePipe } from '@angular/common'
import {
  Component,
  ElementRef,
  HostListener,
  InputSignal,
  OnInit,
  Signal,
  WritableSignal,
  computed,
  signal,
  viewChild,
  input,
  ChangeDetectionStrategy
} from '@angular/core'
import { ActivityClickEmitI, IActivity, ISchedule } from '@core/models/schedule'
import { ActivityComponent } from './components/activity/activity.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-schedule',
  standalone: true,
  imports: [KeyValuePipe, ActivityComponent],
  templateUrl: './schedule.component.html'
})
export class ScheduleComponent implements OnInit {

  // TODO:// POPRAWIC UPDATE DLA SIGNALOW ACTIVITISLOTS!!!!
  private readonly _activities: ISchedule = {
    0: [
      {
        name: 'Zajęcia indywidulane',
        start: '16:40',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Taekwondo / Kickboxing - GR. 11-15 lat',
        start: '17:45',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Kickboxing / K1',
        start: '19:00',
        duration: '1:30',
        desc: '',
        color: '#fff'
      }
    ],
    1: [
      {
        name: 'Taekwondo - GR. 4-6 lat',
        start: '16:00',
        duration: '0:45',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Taekwondo - gr. 7-10 lat',
        start: '17:00',
        duration: '1:00',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Kickboxing - gr. od 15 lat',
        start: '18:00',
        duration: '1:30',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Kickboxing dla kobiet',
        start: '19:30',
        duration: '1:00',
        desc: 'Test test',
        color: '#fff'
      }
    ],
    2: [
      {
        name: 'Zajęcia indywidulane',
        start: '16:40',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Taekwondo / Kickboxing - GR. 11-15 lat',
        start: '17:45',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Kickboxing / K1',
        start: '19:00',
        duration: '1:30',
        desc: '',
        color: '#fff'
      }
    ],
    3: [
      {
        name: 'Taekwondo - GR. 4-6 lat',
        start: '16:00',
        duration: '0:45',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Taekwondo - gr. 7-10 lat',
        start: '17:00',
        duration: '1:00',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Kickboxing - gr. od 15 lat',
        start: '18:00',
        duration: '1:30',
        desc: 'Test test',
        color: '#fff'
      },
      {
        name: 'Kickboxing dla kobiet',
        start: '19:30',
        duration: '1:00',
        desc: 'Test test',
        color: '#fff'
      }
    ],
    4: [
      {
        name: 'Zajęcia indywidulane',
        start: '16:40',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Grupa zawodnicza',
        start: '17:45',
        duration: '1:15',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Boks',
        start: '19:00',
        duration: '1:30',
        desc: '',
        color: '#fff'
      }
    ],
    5: [
      {
        name: 'Zajęcia indywidulane',
        start: '09:00',
        duration: '1:00',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Kickboxing',
        start: '10:00',
        duration: '1:30',
        desc: '',
        color: '#fff'
      },
      {
        name: 'Zajęcia indywidulane',
        start: '11:30',
        duration: '1:00',
        desc: '',
        color: '#fff'
      }
    ]
  }
  private readonly _slotStepInMinutes = 5
  private _timeSlots: WritableSignal<string[]> = signal([])
  private _activeSlotIsVisibleSignal: WritableSignal<boolean> = signal(false)
  private _activeSlotIndex: WritableSignal<number> = signal(-1)
  private _activeDaySlotIndex: WritableSignal<number> = signal(-1)
  private _activitisSlots: WritableSignal<ISchedule> = signal({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: []
  })
  private _activeSlotSignal: Signal<IActivity | null> = computed(() => {
    if (this._activeDaySlotIndex() < 0 || this._activeSlotIndex() < 0)
      return null

    return this._activitisSlots()[this._activeDaySlotIndex()][
      this._activeSlotIndex()
    ]
  })

  private _modal: Signal<ElementRef> = viewChild.required('modal')

  startHour: InputSignal<string> = input('09:00')
  endHour: InputSignal<string> = input('21:00')
  days: InputSignal<string[]> = input([
    'Poniedziałek',
    'Wtorek',
    'Środa',
    'Czwartek',
    'Piątek',
    'Sobota'
  ])

  daysLength = computed(() => {
    console.log(this.days())
    return Object.keys(this.days()).length
  })

  get startHourValue() {
    return this.startHour()
  }
  get endHourValue() {
    return this.endHour()
  }
  get daysArray() {
    return this.days()
  }
  get timeSlots() {
    return this._timeSlots()
  }
  get activitisSlotsObject() {
    return this._activitisSlots()
  }
  get activeSlot(): IActivity | null {
    return this._activeSlotSignal()
  }
  get activeSlotIsVisible(): boolean {
    return this._activeSlotIsVisibleSignal()
  }

  private _generateTimeSlots(): void {
    const [startHour, startMinutes] = this.startHourValue.split(':').map(Number)
    const [endHour, endMinutes] = this.endHourValue.split(':').map(Number)

    const currentTime = new Date()
    currentTime.setHours(startHour, startMinutes, 0, 0)

    const endTime = new Date()
    endTime.setHours(endHour, endMinutes, 0, 0)

    while (currentTime < endTime) {
      const hours = String(currentTime.getHours()).padStart(2, '0')
      const minutes = String(currentTime.getMinutes()).padStart(2, '0')

      this.timeSlots.push(`${hours}:${minutes}`)
      currentTime.setMinutes(currentTime.getMinutes() + this._slotStepInMinutes)
    }

    for (let i = 0; i < this.daysArray.length; i++) {
      this.activitisSlotsObject[i] = Array(this.timeSlots.length).fill('')
    }
  }
  private _assignActivities(): void {
    for (const day in this._activities) {
      const dayIndex = parseInt(day)
      const activities = this._activities[dayIndex]
      for (const activity of activities) {
        const activityStartIndex = this._getSlotIndex(activity.start)
        const activityDurationSlots = this.getDurationInSlots(
          activity.duration,
          this._slotStepInMinutes
        )

        for (let i = 0; i < activityDurationSlots; i++) {
          const slotIndex = activityStartIndex + i

          if (slotIndex < this.timeSlots.length - 1) {
            if (i === 0) {
              this.activitisSlotsObject[dayIndex][slotIndex] = {
                ...activity,
                rowspan: activityDurationSlots
              }
            } else {
              this.activitisSlotsObject[dayIndex][slotIndex] = {
                name: 'blocked',
                start: '',
                duration: '',
                desc: '',
                color: ''
              }
            }
          }
        }
      }
    }
  }
  private _getSlotIndex(startTime: string): number {
    const [hours, minutes] = startTime.split(':').map(String)
    let newStartTime = startTime

    if (hours.length < 2) newStartTime = `0${hours}:${minutes}`

    return this.timeSlots.findIndex((time) => time === newStartTime)
  }

  getDurationInSlots(duration: string, step: number): number {
    const [hours, minutes] = duration.split(':').map(Number)
    return (hours * 60 + minutes) / step
  }
  getActivitiEnd(start: string, duration: string): string {
    const [startHours, startMinutes] = start.split(':').map(Number)
    const [durationHours, durationMinutes] = duration.split(':').map(Number)

    let newMinutes = startMinutes + durationMinutes
    let newHours = startHours + durationHours

    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60)
      newMinutes = newMinutes % 60
    }

    newHours = newHours % 24

    const formattedHours = String(newHours).padStart(2, '0')
    const formattedMinutes = String(newMinutes).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}`
  }
  getSlotHeight(slot: IActivity) {
    if (!slot?.duration) return 0

    const duration = this.getDurationInSlots(
      slot.duration,
      this._slotStepInMinutes
    )

    return duration
  }
  activeSlotHandle(val: ActivityClickEmitI): void {
    const { slotIndex, dayIndex } = val
    const nativeEl = this._modal().nativeElement

    this._activeSlotIsVisibleSignal.set(true)
    this._activeSlotIndex.set(slotIndex)
    this._activeDaySlotIndex.set(dayIndex)
    nativeEl.showModal()
  }
  clearActiveSlot(): void {
    this._activeSlotIsVisibleSignal.set(false)
    this._activeSlotIndex.set(-1)
    this._activeDaySlotIndex.set(-1)
  }

  @HostListener('click', ['$event.target'])
  onButtonClick(target: HTMLElement): void {
    if (target.id === 'closeModal') {
      this.clearActiveSlot()
    }
  }

  ngOnInit() {
    this._generateTimeSlots()
    this._assignActivities()
  }
}
