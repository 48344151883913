export enum ToastType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export type ToastMessage = {
  id: number
  message: string
  type: ToastType
  timeout?: number
}
