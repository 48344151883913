import { isPlatformBrowser } from '@angular/common'
import {
  Injectable,
  PLATFORM_ID,
  computed,
  effect,
  inject,
  signal
} from '@angular/core'

import { BreakpointService } from '@core/services/breakpoint/breakpoint.service'

@Injectable()
export class MobileMenuService {
  private _platformId = inject(PLATFORM_ID)
  private _breakpoint = inject(BreakpointService)

  private _openedTimeout?: NodeJS.Timeout

  private _visible = signal(false)
  private _shouldBeVisible = computed(
    () => this._visible() && !this._breakpoint.isLg()
  )
  private _opened = signal(false)

  constructor() {
    if (isPlatformBrowser(this._platformId)) {
      effect(() => {
        if (this._shouldBeVisible()) {
          document.body.classList.add('lock-body-scroll')
        } else {
          document.body.classList.remove('lock-body-scroll')
        }
      })
    }
  }

  get visible() {
    return this._shouldBeVisible()
  }

  get opened() {
    return this._opened()
  }

  changeCheckboxEvent(event: Event) {
    const target = event.target as HTMLInputElement
    this.change(target.checked)
  }

  change(open: boolean) {
    clearTimeout(this._openedTimeout)

    if (open) {
      this._visible.set(true)
      this._openedTimeout = setTimeout(() => this._opened.set(true), 0)
    } else {
      this._opened.set(false)
      this._openedTimeout = setTimeout(() => this._visible.set(false), 150)
    }
  }
}
