<div class="pb-3">
  <label class="form-control w-full">
    <div class="label">
      @if (labelText) {
        <span class="label-text font-semibold">
          @if (isRequired) {
            <span class="text-error">*</span>
          }
          {{ labelText }}
        </span>
      }
      <!--    <span class="label-text-alt">Top Right label</span>-->
    </div>
    <textarea
      [placeholder]="placeholderText"
      [formControl]="controlObject"
      class="textarea textarea-bordered min-h-44 w-full shadow-lg"
    ></textarea>
    <!--  <div class="label">-->
    <!--    <span class="label-text-alt">Bottom Left label</span>-->
    <!--    <span class="label-text-alt">Bottom Right label</span>-->
    <!--  </div>-->
  </label>

  <app-error-messages [control]="controlObject" />
</div>
