@if (toastsList) {
  <div
    [class]="
      [
        'toast toast-end toast-top z-30 duration-300',
        {
          'top-16': !scroll.isHide
        }
      ] | cn
    "
  >
    @for (toast of toastsList; track toast.id) {
      <div
        [class]="
          [
            'alert relative flex max-w-md items-center pr-8 text-white shadow-lg duration-300',
            {
              'alert-success': toast.type === ToastType.Success,
              'alert-error': toast.type === ToastType.Error,
              'alert-warning': toast.type === ToastType.Warning,
              'alert-info': toast.type === ToastType.Info
            }
          ] | cn
        "
      >
        <i-lucide [img]="icons[toast.type]" />
        <span class="whitespace-normal text-sm drop-shadow-md">{{
          toast.message
        }}</span>
        <button
          class="btn btn-circle btn-ghost btn-sm absolute right-0 top-0 hover:bg-transparent"
          (click)="removeToast(toast.id)"
        >
          &times;
        </button>
      </div>
    }
  </div>
}
