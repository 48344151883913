import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal
} from '@angular/core'
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import { RouterLink } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BicepsFlexed, LucideAngularModule, Mail, Phone } from 'lucide-angular'

import { addressData, contactData } from '@core/constants/mockData.constants'
import { ROUTES_DATA } from '@core/constants/routes.constants'
import { ContactForm } from '@core/models/forms'
import { ToastType } from '@core/models/toast'
import { IconRegistry } from '@core/models/ui'
import { LangService } from '@core/services/lang/lang.service'
import { ToastService } from '@core/services/toast/toast.service'
import { phoneNumberValidator } from '@core/validators/phoneNumber'
import { BreadcrumbsComponent } from '@features/breadcrumbs/breadcrumbs.component'
import { MapComponent } from '@features/map/map.component'
import { CheckboxComponent } from '@shared/components/form/checkbox/checkbox.component'
import { InputComponent } from '@shared/components/form/input/input.component'
import { TextareaComponent } from '@shared/components/form/textarea/textarea.component'
import { BoxButtonDirective } from '@shared/directives/box-button/box-button.directive'
import { RippleDirective } from '@shared/directives/ripple/ripple.directive'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-content-contact',
  standalone: true,
  templateUrl: './content.component.html',
  imports: [
    BreadcrumbsComponent,
    TranslateModule,
    BoxButtonDirective,
    CheckboxComponent,
    ReactiveFormsModule,
    InputComponent,
    LucideAngularModule,
    MapComponent,
    RippleDirective,
    TextareaComponent,
    RouterLink
  ]
})
export class ContentComponent implements OnInit {
  private _lang = inject(LangService)
  private _toast = inject(ToastService)
  private _formBuilder = inject(FormBuilder)
  private _translate = inject(TranslateService)
  private _checkboxText = signal('')

  protected readonly scheduleLink: string
  protected readonly icons: IconRegistry = {
    biceps: BicepsFlexed,
    phone: Phone,
    mail: Mail
  }
  protected readonly address = addressData
  protected readonly contact = contactData

  protected contactForm: ContactForm = this._formBuilder.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    mail: ['', Validators.required],
    phone: ['', [Validators.required, phoneNumberValidator()]],
    subject: [''],
    message: ['', Validators.required],
    terms: [false, Validators.requiredTrue]
  })

  constructor() {
    this.scheduleLink = `/${ROUTES_DATA['timetable'][this._lang.currentLang].path}`
  }

  get pageName() {
    return ROUTES_DATA['contact'][this._lang.currentLang].title
  }

  get checkboxText() {
    return this._checkboxText()
  }

  getAsFormControl(field: string) {
    return this.contactForm.get(field) as FormControl
  }

  onSubmit() {
    // alert(this.contactForm.value)
    this._toast.showToast(
      'Message has been sent successfully!',
      ToastType.Success
    )
  }

  ngOnInit(): void {
    this._translate
      .get([
        'home.contactUs.form.policy.openingText',
        'home.contactUs.form.policy.conjunction',
        'home.contactUs.form.policy.privacyPolicy.text',
        'home.contactUs.form.policy.privacyPolicy.url',
        'home.contactUs.form.policy.terms.text',
        'home.contactUs.form.policy.terms.url'
      ])
      .subscribe((translations) => {
        this._checkboxText.set(`
        ${translations['home.contactUs.form.policy.openingText']}
        <a class="link" target="_blank" href="${translations['home.contactUs.form.policy.privacyPolicy.url']}"
        >${translations['home.contactUs.form.policy.privacyPolicy.text']}</a
        > ${translations['home.contactUs.form.policy.conjunction']}
        <a class="link" target="_blank" href="${translations['home.contactUs.form.policy.terms.url']}"
        >${translations['home.contactUs.form.policy.terms.text']}</a>.
      `)
      })
  }
}
