import {
  Component,
  InputSignal,
  input,
  ChangeDetectionStrategy
} from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'

import { ErrorMessagesComponent } from '@shared/components/error-messages/error-messages.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ErrorMessagesComponent],
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent {
  text: InputSignal<string> = input.required()
  control: InputSignal<FormControl> = input.required()

  get controlObject() {
    return this.control()
  }

  get isRequired() {
    return this.controlObject.hasValidator(Validators.requiredTrue)
  }

  get textValue() {
    return this.isRequired
      ? `<span class="text-error">*</span> ${this.text()}`
      : this.text()
  }
}
