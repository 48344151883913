<div class="relative h-screen w-full bg-black" data-header-dark>
  <video
    class="absolute h-screen w-full object-cover"
    [poster]="poster"
    [src]="videoSrc"
    muted
    autoplay
    loop
  ></video>

  <div
    class="absolute left-1/2 top-1/2 z-10 flex w-full max-w-[400px] -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-8 md:max-w-[600px] lg:max-w-[800px]"
  >
    <div
      class="text-center font-oswald text-3xl leading-[50px] text-white text-shadow md:text-4xl md:leading-[60px] lg:text-6xl lg:leading-[89px]"
      [innerHTML]="
        'home.bannerText' | translate | textWithUnderlines: underlineWords
      "
    ></div>
    <button class="btn btn-warning lg:btn-lg">
      <i-lucide [img]="icons['register']"></i-lucide>
      <span>{{ 'header.register' | translate }}</span>
    </button>
  </div>
</div>
