import {
  Component,
  InputSignal,
  input,
  ChangeDetectionStrategy
} from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'

import { ErrorMessagesComponent } from '@shared/components/error-messages/error-messages.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-input',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ErrorMessagesComponent],
  templateUrl: './input.component.html'
})
export class InputComponent {
  type: InputSignal<string> = input('text')
  label: InputSignal<string> = input('')
  placeholder: InputSignal<string> = input('')
  control: InputSignal<FormControl> = input.required()

  get controlObject() {
    return this.control()
  }

  get isRequired() {
    return this.controlObject.hasValidator(Validators.required)
  }

  get labelText() {
    return this.label()
  }

  get placeholderText() {
    return !this.labelText && this.placeholder() && this.isRequired
      ? `* ${this.placeholder()}`
      : this.placeholder()
  }

  get isEmail() {
    return this.type() === 'email'
  }
}
