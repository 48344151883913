import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { ToastMessage, ToastType } from '@core/models/toast'

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _toastSubject = new BehaviorSubject<ToastMessage[]>([])
  private _idCounter = 0
  private _toastTimeout: { [key: number]: NodeJS.Timeout } = {}

  get toasts$() {
    return this._toastSubject.asObservable()
  }

  showToast(message: string, type: ToastType, timeout = 5000) {
    const id = ++this._idCounter
    const newToast: ToastMessage = { id, message, type }
    const currentToasts = this._toastSubject.value

    this._toastSubject.next([...currentToasts, newToast])

    this._toastTimeout[id] = setTimeout(() => this.removeToast(id), timeout)
  }

  removeToast(id: number) {
    clearTimeout(this._toastTimeout[id])

    const updatedToasts = this._toastSubject.value.filter(
      (toast) => toast.id !== id
    )
    this._toastSubject.next(updatedToasts)
  }
}
