import {
  Component,
  InputSignal,
  OnInit,
  input,
  ChangeDetectionStrategy
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { AuthService } from '@core/services/auth/auth.service'
import { LangService } from '@core/services/lang/lang.service'
import { SeoService } from '@core/services/seo/seo.service'
import { FooterComponent } from '@features/footer/footer.component'
import { HeaderComponent } from '@features/header/header.component'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-default-layout',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './default.component.html',
  providers: [LangService, AuthService]
})
export class DefaultLayoutComponent implements OnInit {
  page: InputSignal<string> = input.required()
  distanceHeaderBlock: InputSignal<boolean> = input(true)

  constructor(
    private seoService: SeoService,
    private translate: TranslateService,
    private lang: LangService
  ) {
    this.translate.use(this.lang.currentLang)
  }

  ngOnInit() {
    this.seoService.setMetaTags(this.page())
  }
}
